<template lang="pug">
  .con(v-if="!$store.state.pcshow")
    img(src='../assets/img/img52.png')
    .btngroup.clearfix
      router-link(to="/thothAPP" :class="$route.path === '/thothAPP'? 'active': ''")
        .companyIntro
          p Thoth
      //- router-link(to="/ProductCenter" :class="$route.path === '/ProductCenter'? 'active': ''")
      //-   .companyIntro
      //-     p 亿联工坊
      router-link(to="/bxcApp" :class="$route.path === '/bxcApp'? 'active': ''")
        .companyIntro
          p 彼仙草
      router-link(to="/goods" :class="$route.path === '/goods'? 'active': ''")
        .companyIntro
          p 食品药品
    .conmian(class="clearfix")
      img(src='../assets/img/img43.png')
      .conright
        h2 优质内容 智能推荐
    .conmian(class="clearfix conmainback")
      .conright
        h2 品质好物 边逛边买
      img(src='../assets/img/img44.png')
    .conmian(class="clearfix")
      img(src='../assets/img/img45.png')
      .conright
        h2 专家直播 干货满满
    .conmian(class="clearfix conmainback")
      .conright
        h2 个人中心 分类管理
      img(src='../assets/img/img46.png')
  .productCenter(v-else)
    .exhibition1
      //- .mins
      //-   img(src="../assets/img/productCenter/bxc1.png")
      //-   .right(style="width:715px;margin-left:88px;padding-bottom:32px;")
      //-     h2(style="margin-top:100px;margin-bottom:40px;") 彼仙草 APP
      //-     p(style="line-height:39px;margin-bottom:45px;") 聚焦互联网大健康市场，以健康教育为抓手，慢病管理跟踪服务为基石打造全场景互联网+大健康平台
      //-     .rights(style="padding-right:150px;")
      //-       .rig(style="text-align:center;margin-right:70px;")
      //-         img(src="../assets/img/productCenter/bxc.png")
      //-         div(style="margin-top:13px;padding-right:10px;width:145px;") Android 下载
      //-       .rig(style="text-align:center")
      //-         img(src="../assets/img/productCenter/bxc.png")
      //-         div(style="margin-top:13px;padding-right:10px;width:145px;") IPhone 下载
      img(src="../assets/img/productCenter/m1.png" style="width:100%;")
    .exhibition2
      .mins
        .right
          h3(style="margin-top:250px;margin-bottom:24px") 精彩视讯
          h3(style="margin-top:0px;font-size:24px;") 健康科普答疑解难
        img(src="../assets/img/productCenter/m5.png" style="width:298px;")
    .exhibition3
      .mins
        img(src="../assets/img/productCenter/m4.png" style="width:298px;")
        .right
          h3(style="margin-top:250px;margin-bottom:24px") 优质原创
          h3(style="margin-top:0px;font-size:24px;") 专业内容 IP赋能
    .exhibition4
      .mins
        .right
          h3(style="margin-top:250px;margin-bottom:24px") 科普直播 
          h3(style="margin-top:0px;font-size:24px;") 万千医生互动热聊
        img(src="../assets/img/productCenter/m3.png" style="width:298px;")
    .exhibition3
      .mins
        img(src="../assets/img/productCenter/m2.png" style="width:298px;")
        .right
          h3(style="margin-top:250px;margin-bottom:24px") 海量课程
          h3(style="margin-top:0px;font-size:24px;") 优质内容天天充电
</template>

<script>
export default{
  name: 'BxcApp',
  data() {
    return {
      
    }
  }
}
</script>

<style scoped lang="stylus">
  @media screen and (max-width: 960px)
    .con
      width: 100%;
      background: #F5F5F5;
      border-bottom: 0.35rem solid #eaeaea
      >img
        display: block
        width:7.5rem;
        height: 4rem;
    .btngroup
        width: 6.8rem;
        padding-left:0.35rem;
        padding-right:0.35rem; 
        padding-bottom:0.35rem;
        a
          display: block;
          width: 2rem;
          height: 0.68rem;
          background: #FFFFFF;
          float: left
          margin-top: 0.3rem;
          margin-right: 0.4rem;
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.68rem;
          text-align: center; 
        a.active
          background: #0085D0;
          color: #ffffff;
        a:nth-child(3)
          margin-right:0;
    .conmian
      display: block;
      width: calc( 100% - 0.88rem );
      padding-left: 0.88rem;
      background: #fff;
      >img
        display: block
        float: left
        width: 1.59rem
        height: 3.05rem
        padding-bottom: 0.49rem
        padding-top: 0.49rem
      .conright
        float: left
        h2
          padding-left: 0.4rem;
          padding-top: 1.47rem;
          font-size: 0.3rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.45rem;
        p
          width: 3.7rem;
          padding-left: 0.4rem;
          // height: 0.24rem;
          font-size: 0.24rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.45rem;
          padding-top: 0.1rem
    .conmainback
      background: #eaeaea
      .conright
        h2
          padding-left: 0rem;
          padding-right: 1.67rem;
        p
          padding-left: 0rem;
          padding-right: 1.67rem;
  @media screen and (min-width: 960px)
    .productCenter
      padding-top:90px;
    .exhibition2,.exhibition4
      background #fff !important
    .exhibition3
      background #F8F9FA !important
    .exhibition1,.exhibition2,.exhibition3,.exhibition4
      background url("../assets/img/productCenter/img.jpg") no-repeat center center
      background-size 100% 100%
      .mins
        width 750px
        padding  117px 0 110px
        margin 0 auto
        display flex
        justify-content: space-between;
        .right
          width 345px
          h2
            font-size: 48px;
            color: #333333;
            margin-top 154px
          h3
            margin-top 39px
            font-size: 30px;
            color: #333333;
            margin-bottom 55px
          p
            font-size: 20px;
            margin-bottom 20px
            color #666666
            &:nth-of-type(3)
              margin-bottom 60px
          .rights
            display flex
            justify-content: space-between;
            .rig
              div 
                width: 204px;
                border-radius: 5px;
                border: 1px solid #333333;
                height: 39px;
                line-height 39px
                font-size: 22px;
                color: #333333;
                padding-left 47px;
              &:nth-of-type(1)
                div
                  background url("../assets/img/productCenter/img5.png") no-repeat 5px center
              &:nth-of-type(2)
                div
                  background url("../assets/img/productCenter/img4.png") no-repeat 5px center
                  margin-top 20px
          
</style>  